import Vue from "vue";
import Router from "vue-router";

import layout from "@/components/layout";
import Login from "@/views/login/";
import store from "@/store";
import { getCookie } from "@/static/js/cookie";

Vue.use(Router);
const projectName = process.env.VUE_APP_ProjectName;

export const constantRouterMap = [
  {
    path: "/login",
    component: Login,
  },
];
let router = new Router({
  routes: constantRouterMap,
});

export const asyncRouterMap = [
  {
    path: "/",
    name: "layout",
    component: layout,
    meta: {
      title: "首页",
      icon: "dashboard",
    },
    children: [
      {
        path: "/list/:id",
        name: "list",
        component: (resolve) => require(["@/views/list/index"], resolve),
        meta: {
          title: "列表",
          icon: "apartment",
        },
      },
      {
        path: "/email/",
        name: "email",
        component: (resolve) => require(["@/views/email/index"], resolve),
        meta: {
          title: "邮箱配置",
          icon: "mail",
          roles: "super",
        },
      },
      {
        path: "/user/",
        name: "user",
        component: (resolve) => require(["@/views/user/index"], resolve),
        meta: {
          title: "用户设置",
          icon: "user",
          roles: "super",
        },
      },
    ],
  },
  {
    path: "/lockscreen",
    component: (resolve) => require(["@/views/err/lockscreen"], resolve),
  },
  { path: "*", component: (resolve) => require(["@/views/err/404"], resolve) },
];

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const whiteList = ["/login", "/Printing"]; // 白名单
router.beforeEach((to, from, next) => {
  let token = getCookie("token");

  if (token) {
    // 判断是否有token
    /* has token*/
    if (!store.getters.roles) {
      // 判断当前用户是否已拉取完user_info信息
      // console.log(token,'token')
      console.log(token, "token22");
      let role = JSON.parse(token);
      let r = "super";
      if (role) {
        store.commit("SET_ROLES", r);
        store.dispatch("GenerateRoutes", r).then(() => {
          // 根据roles权限生成可访问的路由表
          router.addRoutes(store.getters.addRouters); // 动态添加可访问路由表
          next({ ...to, replace: true }); // hack方法 确保addRoutes已完成 ,设置replace:true，这样导航就不会留下历史记录
        });
      } else {
        console.log("获取role失败");
        store.dispatch("LogOut").then(() => {
          next({ path: "/login" });
        });
      }
    } else {
      next();
    }
  } else {
    /*没有token的话，判断是否在白名单中，是就进入，不是就跳转到登录页面*/
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next();
    } else {
      next(`/login?url=${to.path}`); // 否则全部重定向到登录页
    }
  }

  next(); //锁屏结束
});

router.afterEach((to) => {
  //from参数暂时用不到
  if (to.meta.title) {
    document.title = to.meta.title + " - " + projectName;
  }
});
export default router;
